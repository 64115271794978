import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ImovelLancamento } from 'src/app/shared/models/imovel-lancamento';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { RealCurrencyPipe } from 'src/app/shared/util/real-currency-pipe';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  hoverClass = 'shadow-none';
  @Input() release: ImovelLancamento;
  @Input() enableLogo: boolean = false;

  constructor(
    private ga: AnalyticsService,
    private router: Router,
    private sm: SessionManager
  ) {}

  public get fotoDestaqueUrl(): string {
    if (this.release?.fotoDestaqueEmpreendimento?.length > 0)
      // pega a foto pequena, pois algumas estavam com 4mb ou mais
      return this.release?.fotoDestaqueEmpreendimento?.replace('.jpg', '_p.jpg');
    else return 'assets/img/img_loading.webp';
  }

  public get empreendimento(): string {
    return this.release?.empreendimento;
  }

  public get bairro(): string {
    return this.release?.bairro;
  }

  public get areas(): string {
    var a1 = this.release?.minArea;
    var a2 = this.release?.maxArea;
    if (!a1 && a2) a1 = a2;
    else if (a1 && !a2) a2 = a1;
    if (a1 != a2) return `${a1}m<sup>2</sup> a ${a2}m<sup>2</sup>`;
    else if (a1 > 0) return `${a1}m<sup>2</sup>`;
    else return '';
  }

  public get quartos(): string {
    var retval = [];

    // dormitórios
    var d1 = this.release?.minDormitorios;
    var d2 = this.release?.maxDormitorios;
    if (!d1 && d2) d1 = d2;
    else if (d1 && !d2) d2 = d1;
    if (d1 != d2) retval.push(`${d1} a ${d2} dormitórios`);
    else if (d1) retval.push(`${d1} dormitório${d1 < 2 ? '' : 's'}`);

    // suítes
    var s1 = this.release?.minSuites;
    var s2 = this.release?.maxSuites;
    if (!s1 && s2) s1 = s2;
    else if (s1 && !s2) s2 = s1;
    if (s1 != s2) retval.push(`${s1} a ${s2} suítes`);
    else if (s1 > 0) retval.push(`${s1} suíte${s1 < 2 ? '' : 's'}`);

    return retval.join(' até ');
  }

  public get vagas(): string {
    var v1 = this.release?.minVagas;
    var v2 = this.release?.maxVagas;
    if (!v1 && v2) v1 = v2;
    else if (v1 && !v2) v2 = v1;
    if (v1 != v2) return `${v1} a ${v2} vagas`;
    else if (v1 > 0) return `${v1} vaga${v1 < 2 ? '' : 's'}`;
    else return '';
  }

  public get valores(): string {
    var v1 = this.release?.minValor;
    var v2 = this.release?.maxValor;

    const rp = new RealCurrencyPipe();
    if (!v1 && !v2) return '';
    else if (!v1 && v2) v1 = v2;
    else if (v1 && !v2) v2 = v1;

    if (v1 != v2) return `de ${rp.transform(v1, 0)}<br/> a ${rp.transform(v2, 0)}`;
    else if (v1 > 0) return rp.transform(v1, 0);
    else return '';
  }

  public get previsaoEntrega(): number {
    var retval = this.release?.dataEntrega;
    if (retval && new Date(retval * 1000).getFullYear() >= new Date().getFullYear()) return retval;
    return null;
  }

  onSearch() {
    // Evento do Google Analytics
    this.ga.trackEvent(this.empreendimento, 'releases', this.empreendimento);

    // limpa a busca atual, para realizar uma nova
    this.sm.clearSearch();

    // busca de acordo com o empreendimento
    this.router.navigate(['imoveis/status', this.release?.statusUrl, 'cidade', this.release?.cidadeSlug], {
      queryParams: { em: this.release?.empreendimentoSlug, inc: true, o: 'maior-area' },
    });
  }
}
