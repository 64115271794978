import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ImovelLancamento } from 'src/app/shared/models/imovel-lancamento';
import { Search } from 'src/app/shared/models/search';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-lancamento-empreendimento',
  templateUrl: './lancamento-empreendimento.component.html',
  styleUrls: ['./lancamento-empreendimento.component.scss'],
})
export class LancamentoEmpreendimentoComponent implements OnInit, OnChanges {
  releases$: Observable<ImovelLancamento[]>;
  @Input() cidadeSlug: string = 'porto-alegre';

  constructor(
    private propertyService: ImovelService,
    private sm: SessionManager
  ) {}

  ngOnInit(): void {
    this.releases$ = this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.releases$ = this.loadData();
  }

  loadData() {
    if (!this.sm.isRegionDefined()) this.cidadeSlug = undefined;
    return this.propertyService.getEmpreendimentosIncorporadoras(this.cidadeSlug).pipe(
      catchError(error => {
        console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar empreendimentos das incorporadoras.'));
        return of([]);
      })
    );
  }
}
